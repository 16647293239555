<template>
<div id="app" style="padding:15px">
    <el-row>
      <el-col :span="24">
        <el-page-header @back="goSelect" content="CRT点位管理"> </el-page-header>
      </el-col>
    </el-row>
    <el-row :gutter="20">
        <el-col :span="12" :offset="0">
            <el-select v-model="crtId" @change="crtInfoChanged" filterable placeholder="请选择">
                <el-option v-for="item in crtInfoList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
        </el-col>
    </el-row>
    <el-row :gutter="20">
        <el-col :span="19" :offset="0">
            <el-row :gutter="0">
                <el-col :span="24" :offset="0">
                    <div id="overburden">
                        <el-popover placement="right-start" trigger="click" @show="popoverShow = true" @hide="popoverHide">
                            <div>
                                <el-row :gutter="20">
                                    <el-col :span="8" :offset="0">
                                        <el-input v-model="deviceInfoQuery.id" placeholder="设备ID" size="normal" @input="getNoCoordinateDeviceInfoList" clearable></el-input>
                                    </el-col>
                                    <el-col :span="8" :offset="0">
                                        <el-input v-model="deviceInfoQuery.typeName" placeholder="设备类型" size="normal" @input="getNoCoordinateDeviceInfoList" clearable></el-input>
                                    </el-col>
                                    <!-- <el-col :span="8" :offset="0">
                                        <el-input v-model="deviceInfoQuery.place" placeholder="安装位置" size="normal" @input="getNoCoordinateDeviceInfoList" clearable></el-input>
                                    </el-col> -->
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="0">
                                        <el-table :data="deviceInfoList" max-height="400" highlight-current-row @current-change="handleCurrentChange">
                                            <el-table-column property="id" label="设备编号"></el-table-column>
                                            <el-table-column property="typeName" label="设备类型"></el-table-column>
                                            <!-- <el-table-column property="place" label="安装位置"></el-table-column> -->
                                        </el-table>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="0">
                                        <span>图标尺寸(单位：px)</span>
                                        <el-slider :min="5" :max="200" v-model="crtDeviceCoordinateInfo.iconWidth"></el-slider>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10" :offset="14">
                                        <el-button size="default" @click="closePopover">取消</el-button>
                                        <el-button type="primary" size="default" @click="realAddCoordinate">添加</el-button>
                                    </el-col>
                                </el-row>
                            </div>

                            <div id="showListForCheck" slot="reference">
                                <i class="el-icon-location"></i>
                            </div>
                        </el-popover>
                        <!--前端设备点位-->
                        <img v-for="item in crtDeviceCoorcrtDinateList" :id="item.deviceInfoId" :src="baseUrl+item.iconUrl" :style="{'left':(parseFloat(item.coordinatex)*crtWid-parseFloat(item.iconWidth)/2).toString()+'px','top':(parseFloat(item.coordinatey)*crtHei-parseFloat(item.iconWidth)/2).toString()+'px','width':item.iconWidth+'px','position':'absolute','z-index':'2','cursor':'pointer'}" @click="showDeviceInfo(item)"></img>
                        <img v-if="crtId!=''" id="crt" :src="baseUrl+crtUrl" @click="addCoordinate"></img>
                    </div>
                    <div v-if="crtId==''" id="tempText">请选择CRT...</div>
                </el-col>
            </el-row>
        </el-col>
        <el-col :span="5" :offset="0">
            <el-row :gutter="20">
                <el-col :span="12" :offset="0">
                    <el-input v-model="addedDeviceInfoQuery.id" placeholder="设备ID" size="normal" @input="getNoCoordinateDeviceInfoList" clearable></el-input>
                </el-col>
                <el-col :span="12" :offset="0">
                    <el-input v-model="addedDeviceInfoQuery.typeName" placeholder="设备类型" size="normal" @input="getNoCoordinateDeviceInfoList" clearable></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="0">
                <el-col :span="24" :offset="0">
                    <el-table :data="addedDeviceInfoList" style="width: 100%">
                        <el-table-column prop="id" label="设备编号" width="120" align="center"></el-table-column>
                        <el-table-column prop="typeName" label="设备类型" width="100" align="center"></el-table-column>
                        <el-table-column label="定位" width="180" align="center">
                            <template slot-scope="scope">
                                <el-button @click="handlePosition(scope.row.id)" @blur="recoveryPosition(scope.row.id)" type="danger" size="small">定位</el-button>
                                <el-button @click="recoveryPosition(scope.row.id)" type="danger" size="small">取消定位</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-col>
    </el-row>

    <el-dialog title="设备信息详情" :visible.sync="deviceInfoDialogVisible" width="30%" @close="clearDeviceInfo">
        <el-form label-width="120px" :model="deviceInfo">
            <el-form-item label="设备ID">
                <el-input v-model="deviceInfo.id" disabled />
            </el-form-item>
            <el-form-item label="设备类型">
                <el-input v-model="deviceInfo.typeName" disabled />
            </el-form-item>
            <el-form-item label="属性">
                <el-input v-model="deviceInfo.name" disabled />
            </el-form-item>
            <el-form-item label="值">
                <el-input v-model="deviceInfo.value" disabled />
            </el-form-item>

            <el-form-item label="尺寸">
                <span>图标尺寸(单位：px)</span>
                <el-slider :min="5" :max="100" v-model="crtDeviceCoordinateInfo.iconWidth" @change="updateIconSize"></el-slider>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
             <el-button @click="deviceInfoDialogVisible= false">取 消</el-button>
             <el-button type="danger" @click="removeCoordinate">删除点位</el-button>
        </div>
    </el-dialog>

</div>
</template>

<script>
import crtDeviceCoordinate from "@/api/manageApi/CrtDeviceCoordinate";
import CRT from "@/api/manageApi/CrtInfo";
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
import BaseData from "@/assets/config/BaseData.js";

export default {
  data() {
    return {
      LSX: 0,
      LSY: 0,
      //crt信息列表
      crtInfoList: [],
      //当前选中的crt对象
      crtInfo: {},
      crtId: "",
      crtUrl: "",
      //未绑定点位的设备信息列表
      deviceInfoList: [],
      //已绑定点位的设备信息列表
      addedDeviceInfoList: [],
      //点位列表
      crtDeviceCoorcrtDinateList: [],
      //添加点位的宽度和高度比例  imgWid为 距离左边界的距离/图片的宽度  imgHei为 距离上边界的距离/图片的高度
      crtDeviceCoordinateInfo: {
        id: "",
        deviceInfoId: "",
        crtPictureId: "",
        coordinatex: 0,
        coordinatey: 0,
        iconWidth: 15,
      },

      //添加点位弹框内模糊查询
      deviceInfoQuery: {
        id: "",
        typeName: "",
        // place: "",
      },
      addedDeviceInfoQuery: {
        id: "",
        typeName: "",
        crtPictureId: "",
      },
      //设备列表当前选中
      currentRow: null,
      //设备信息详情
      deviceInfo: {},

      //当前crt图的宽度
      crtWid: 0,
      //当前crt图的高度
      crtHei: 0,
      //图片虚拟路径前缀，用于访问服务器以获取图片
      baseUrl: BaseData.baseUrl,
      //添加点位弹框展示状态
      popoverShow: false,
      //设备信息详情弹框标志位
      deviceInfoDialogVisible: false,
    };
  },
  created() {
    this.init();
  },
  watch: {
    //监听弹框是否展示   若果弹框展示图标则展示  弹框不展示图标隐藏
    popoverShow(newVal, oldVal) {
      if (newVal) {
        document.getElementById("showListForCheck").style.opacity = 1;
      } else {
        document.getElementById("showListForCheck").style.opacity = 0;
      }
    },
  },
  methods: {
    init() {
      this.getCrtInfoList();
      this.getNoCoordinateDeviceInfoList();
    },
    goSelect() {
      this.$router.push("/Select");
    },
    //條件查询全部未绑定点位的设备信息
    getNoCoordinateDeviceInfoList() {
      deviceInfoManagement
        .getNoCoordinateDeviceInfoList(this.deviceInfoQuery)
        .then((res) => {
          this.deviceInfoList = res.data.data.list;
        });
    },
    // 條件查询全部已绑定点位的设备信息
    getAddedDeviceInfoList() {
      deviceInfoManagement
        .getAddedDeviceInfoList(this.addedDeviceInfoQuery)
        .then((res) => {
          this.addedDeviceInfoList = res.data.data.list;
        });
    },
    // // 定位已绑定点位的设备
    // handlePosition(id) {
    //   var hr = document.getElementsByClassName("heart_beat");
    //   if (hr.length != 0) {
    //     hr[0].classList.remove("heart_beat");
    //   }

    //   document.getElementById(id).classList.add("heart_beat");
    // },
    // // 按钮失去焦点时取消定位设备
    // recoveryPosition(id) {
    //   document.getElementById(id).classList.remove("heart_beat");
    // },

    handlePosition(id) {
      var hr = document.getElementsByClassName("heart_red");
      if (hr.length != 0) {
        hr[0].classList.remove("heart_red");
      }

      document.getElementById(id).classList.add("heart_red");
    },
    // 按钮市区焦点时取消定位设备
    recoveryPosition(id) {
      document.getElementById(id).classList.remove("heart_red");
    },

    //查詢全部CRT图片信息
    getCrtInfoList() {
      CRT.getAllCrt().then((res) => {
        this.crtInfoList = res.data.data.list;
      });
    },

    //设备信息列表当前选中改变时触发
    handleCurrentChange(val) {
      this.currentRow = val;
      if (val) {
        this.crtDeviceCoordinateInfo.deviceInfoId = this.currentRow.id;
      }
    },
    //展示点位信息
    showDeviceInfo(item) {
      this.crtDeviceCoordinateInfo.id = item.id;
      this.crtDeviceCoordinateInfo.iconWidth = parseInt(item.iconWidth);
      // TODO 展示点位信息
      deviceInfoManagement.getDeviceInfoById(item.deviceInfoId).then((res) => {
        this.deviceInfo = res.data.data.deviceInfoVo;
        console.log(this.deviceInfo);
        console.log(this.deviceInfo);
        console.log(this.deviceInfo);
        console.log(this.deviceInfo);
        console.log(this.deviceInfo);
        this.deviceInfoDialogVisible = true;
      });
    },
    //关闭设备详情的时候清空设备信息详情
    clearDeviceInfo() {
      this.clearCrtDeviceCoordinateInfo();
      this.deviceInfo = {};
    },
    updateIconSize() {
      crtDeviceCoordinate
        .updateIconSize(this.crtDeviceCoordinateInfo)
        .then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //刷新
          if (res.data.success) {
            this.getCoordinateList();
            this.getNoCoordinateDeviceInfoList();
          }
        });
    },

    /************************图片操作************************/
    //弹框隐藏是触发
    popoverHide() {
      this.popoverShow = false;
      this.currentRow = null;
      this.clearCrtDeviceCoordinateInfo();
    },
    //crt图片改变时的回调函数
    crtInfoChanged() {
      this.getNoCoordinateDeviceInfoList();
      var _this = this;
      this.crtInfoList.forEach((item) => {
        if (item.id == this.crtId) {
          this.crtUrl = item.url;
        }
      });
      this.crtDeviceCoordinateInfo.crtPictureId = this.crtId;
      this.addedDeviceInfoQuery.crtPictureId = this.crtId;
      setTimeout(function () {
        var crt = document.getElementById("crt");
        _this.crtWid = crt.width;
        _this.crtHei = crt.height;
        _this.getCoordinateList();
        _this.getAddedDeviceInfoList();
      }, 1000);
    },
    //删除点位
    removeCoordinate() {
      this.$confirm(
        "此操作将永久删除该CRT以及该CRT绑定的点位, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        //点击确定，指定then方法
        crtDeviceCoordinate
          .removeCoordinateById(this.crtDeviceCoordinateInfo.id)
          .then((res) => {
            //提示信息
            this.$message({
              type: res.data.success ? "success" : "warning",
              message: res.data.message,
            });
            //刷新
            if (res.data.success) {
              this.getCoordinateList();
              this.getNoCoordinateDeviceInfoList();
              this.getAddedDeviceInfoList();
              this.deviceInfoDialogVisible = false;
            }
          });
      });
    },
    //根据当前选中的crt图片id获取点位
    getCoordinateList() {
      crtDeviceCoordinate.getCoordinateByCrtId(this.crtId).then((res) => {
        this.crtDeviceCoorcrtDinateList = res.data.data.list;
      });
    },

    //取消
    closePopover() {
      document.getElementById("app").click();
    },
    //添加点位
    addCoordinate(e) {
      e = e || window.event;
      let xx = e.offsetX;
      let yy = e.offsetY;

      let im = document.getElementById("crt"); // CRT背景图片

      // 计算点位和CRT图的比值
      this.crtDeviceCoordinateInfo.coordinatex = xx / im.width;
      this.crtDeviceCoordinateInfo.coordinatey = yy / im.height;

      this.LSX = xx / im.width;
      this.LSY = yy / im.height;
      setTimeout(function () {
        let a = document.getElementById("showListForCheck");
        a.click();
      }, 1000);
      document.getElementById("showListForCheck").style.left = xx - 7 + "px";
      document.getElementById("showListForCheck").style.top = yy - 13 + "px";
    },
    //确认添加点位
    realAddCoordinate() {
      //校验是否选择设备
      if (!this.crtDeviceCoordinateInfo.deviceInfoId) {
        //提示信息
        this.$message({
          type: "warning",
          message: "请选择设备！",
        });
      } else {
        this.crtDeviceCoordinateInfo.coordinatex = this.LSX;
        this.crtDeviceCoordinateInfo.coordinatey = this.LSY;

        //添加绑定关系
        crtDeviceCoordinate
          .addCrtDeviceCoordinate(this.crtDeviceCoordinateInfo)
          .then((res) => {
            //提示信息
            this.$message({
              type: res.data.success ? "success" : "warning",
              message: res.data.message,
            });

            this.deviceInfoQuery.id = "";
            //刷新
            if (res.data.success) {
              //根据当前选中的crt图片id获取点位
              this.getCoordinateList();
              // 條件查询全部已绑定点位的设备信息
              this.getAddedDeviceInfoList();
              //條件查询全部未绑定点位的设备信息
              this.getNoCoordinateDeviceInfoList();
              //取消
              this.closePopover();
            }
          });
      }
    },
    clearCrtDeviceCoordinateInfo() {
      this.crtDeviceCoordinateInfo = {
        id: "",
        deviceInfoId: "",
        crtPictureId: this.crtId,
        coordinatex: 0,
        coordinatey: 0,
        iconWidth: 15,
      };
    },
    /************************************************/

    //返回上一级
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

#overburden {
  position: absolute;
}

#crt {
  width: 1300px;
  position: relative;
}
#tempText {
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
  margin-top: 250px;
}

#showListForCheck {
  opacity: 0;
  position: absolute;
  color: red;
  z-index: 2;
}
</style>
